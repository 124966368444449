import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

export default ({ data }) => {
  return (
    <Layout pathEn="/en/">
      <SEO
        title="Nos patients l'ont fait - Dr Philippe Roure"
        description="Rééducations spécifiques pour nos patients après intervention de chirurgie du membre supérieur : danseuse professionnelle, kayakiste, athlétisme, violoniste."
      />
      <Helmet>
        <script type="application/ld+json">{`
      {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Nos patients l'ont fait",
          "item": "https://www.docteurphilipperoure.com/nos-patients-l-ont-fait/"
        }]
    }
  `}</script>
      </Helmet>
      <Wrapper>
        <H1>Nos patients l'ont fait</H1>
        <hr />
        <GridWrapper>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <GridItem key={node.id}>
              <div>
                <Link
                  to={node.fields.slug}
                  css={css`
                    text-decoration: none;
                  `}
                >
                  <Img
                    fluid={node.frontmatter.image.childImageSharp.fluid}
                    alt={node.frontmatter.imageAlt}
                    css={css`
                      transition: all 0.2s ease-in-out;
                      margin: 0 auto;
                    `}
                  />

                  <H2>{node.frontmatter.title}</H2>
                </Link>
              </div>
            </GridItem>
          ))}
        </GridWrapper>
      </Wrapper>
    </Layout>
  )
}

const H1 = styled.h1`
  font-size: 2.1em;
  margin-bottom: 0.5em;
  @media (max-width: 678px) {
    font-size: 2em;
    line-height: 1.1em;
    padding-top: 1em;
  }
`

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 0.5em auto 2.5em;
  padding: 2.25em 2em 1em 4em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 1024px) {
    padding: 2.25em 1em 1em 1em;
  }
  @media (max-width: 678px) {
    padding: 0em 1em 0.5em;
  }
`

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  grid-template-row: 1fr 1fr;
  grid-gap: 4%;
  margin-top: 3em;
  margin-bottom: 60px;
  padding-bottom: 100px;
  @media (max-width: 678px) {
    display: block;
    padding-bottom: 20px;
    margin-bottom: 0;
  }
`

const GridItem = styled.div`
  .iframe-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  @media (max-width: 678px) {
    margin-bottom: 25px;
  }
`

const H2 = styled.h2`
  font-size: 0.96em;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  margin-top: 10px;
  line-height: 1.35em;
  margin-bottom: 20px;
`

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: frontmatter___poids, order: DESC }
      filter: { frontmatter: { type: { eq: "patients" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            imageAlt
            image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
